@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700,800&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900&display=swap&subset=latin-ext");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  .btn {
    padding: 8px 30px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background 0.4s;

    &.btn-default {
      background: @color1;
      border: 1px solid @color1;
      color: #fff;

      &:hover {
        background: darken(@color1, 10%);
        border: 1px solid darken(@color1, 10%);
      }
    }

    &.btn-border {
      background: #fff;
      border: 1px solid @color1;
      color: @color1;

      &:hover {
        color: #fff;
        background: @color1;
      }
    }
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }
}

@color1: #00a651;
@color2: #fff200;

.select-wrapper {
  position: relative;
  display: flex;
  background: #f1f1f1;

  &:before {
    content: "";
    width: 2px;
    height: 30px;
    background: @color1;
  }

  select {
    cursor: pointer;
    padding: 5px 100px 5px 10px;
    border: none;
    background: #f1f1f1;
    height: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 13px;
    appearance: none;
    outline: none;
    display: block;
    color: #666;
    width: 100%;
  }

  &:after {
    cursor: pointer;
    content: "";
    height: 10px;
    width: 10px;
    background: url("../img/pictures/down-chevron.svg") center;
    background-size: cover;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;

  .radio-box {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-transform: none;
    color: #414141;
    font-weight: 300;
    text-align: left;
    transition: all 0.3s;

    &:before {
      content: "";
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      display: block;
      background: url("../img/pictures/checkbox.svg") no-repeat;
      margin-right: 10px;
      transition: all 0.3s;
    }
  }

  input {
    visibility: hidden;
    position: absolute;
    width: auto !important;
  }
}

.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;

  &:before {
    background: url("../img/pictures/checkbox-checked.svg") no-repeat;
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px 25px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .product-service {
    margin-top: 30px;
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    width: 23%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .badge-wrapper {
      position: absolute;
      z-index: 111;
      top: 0;
      left: -1px;
      width: calc(~"100% + 2px");
      display: flex;
      justify-content: space-between;

      .badge {
        width: 70px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 9px;
        border-radius: 2px 2px 0 0;
        transform: translateY(-100%);
        background: #f1f1f1;

        &.promotion {
          border-top: 1px solid tomato;
          color: tomato;
          display: none;
        }

        &.new {
          border-top: 1px solid olivedrab;
          color: olivedrab;
          display: none;
        }

        &.bestseller {
          border-top: 1px solid dodgerblue;
          color: dodgerblue;
          display: none;
        }
      }
    }

    .service-image-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;
      display: block;
      transition: all 0.4s;
      background: #fff;
      position: relative;

      .service-image {
        text-align: center;
        position: relative;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 7px;

        img {
          width: 100%;
        }
      }

      &.active {
        &:after {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(211, 14, 14, 1);
          box-shadow: 0px 0px 8px 0px rgba(211, 14, 14, 0.4);
        }
      }
    }

    .service-quantity {
      margin-top: 15px;
      display: flex;
      align-items: center;

      p {
        font-weight: 700;
        font-size: 12px;
      }

      .quantity-inner {
        display: flex;
        align-items: center;
        margin-left: 15px;
      }

      input {
        width: 55px;
        height: 25px;
        border-radius: 4px 0 0 4px;
        border: 1px solid @color1;
        border-right: none;
        font-size: 14px;
        outline: none;
        padding-left: 10px;
        transition: all 0.4s;
        text-align: center;
        -webkit-appearance: none;
        appearance: none;

        &.active {
          border: 1px solid rgba(211, 14, 14, 1);
          border-right: none;
        }
      }

      button {
        height: 25px;
        width: 25px;
        border-radius: 0 4px 4px 0;
        border: none;
        background: @color1;
        color: #fff;
        cursor: pointer;
        transition: background 0.4s;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 14px;
        }

        &:hover {
          background: darken(@color1, 10%);
        }
      }
    }

    .name {
      margin-top: 15px;
      padding: 0 5px;
      color: #222;
      font-weight: 600;
      font-size: 14.5px;
      display: block;
      transition: all 0.4s;

      &.list-view {
        display: none;
      }
    }

    .symbol {
      margin-top: 5px;
      padding: 0 5px;
      font-size: 12px;
      color: #999;

      strong {
        color: @color1;
      }

      .lack {
        color: tomato;
      }

      &.list-view {
        display: none;
      }
    }

    .price {
      margin-top: 10px;
      color: @color1;
      padding: 0 5px;
      font-size: 18px;
      letter-spacing: -0.5px;
      font-weight: 600;

      small {
        font-size: 18px;
        font-weight: 600;
        color: #444;
        margin-right: 5px;
        position: relative;
        display: none;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 1px;
          width: 100%;
          height: 1px;
          background: #999;
          transform: rotate(-10deg);
        }
      }

      span {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
    }

    .price-small {
      padding: 0 5px;
      font-size: 12px;
      color: #999;
    }

    .type {
      padding: 0 5px;
      margin-top: 10px;
      font-size: 12px;
      color: #999;

      strong {
        color: @color1;
      }
    }

    .footer-quantity {
      margin-top: 15px;
      padding: 0 5px;
      display: flex;

      p {
        font-size: 11px;
        color: #333;

        &:nth-child(2) {
          margin-left: 7px;
          font-weight: 700;
          color: @color1;
        }
      }
    }

    .btn-more {
      margin-top: 17px;
      text-align: center;
      background: #fff;
      margin-left: -1px;
      transform: translateY(2px);
      width: calc(~"100% + 2px");
      padding: 5px;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 2px;
      color: #444;
      display: block;
      border: none;
      transition: all 0.4s;
    }

    &.promotion {
      .promotion {
        display: block !important;
      }

      .price {
        color: tomato;

        small {
          display: inline-block;
        }
      }
    }

    &.new {
      .new {
        display: block !important;
      }
    }

    &.bestseller {
      .bestseller {
        display: block !important;
      }
    }

    &:hover {
      .name {
        color: @color1;
      }

      .btn-more {
        background: @color1;
        color: #fff;
      }
    }

    &.service-hidden {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      visibility: hidden;
      opacity: 0;
      width: 23%;
    }
  }

  .service-hidden {
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    visibility: hidden;
    opacity: 0;
  }

  &.grid-list {
    .product-service {
      width: 100% !important;
      flex-direction: row;
      justify-content: flex-start;

      header {
        min-width: 17%;
        margin-right: 15px;
      }

      .footer {
        margin-top: 10px;
      }

      .badge-wrapper {
        right: auto;
        left: 0;
        top: 10px;
      }

      .service-image-wrapper {
        &.active {
          &:after {
            left: auto;
            right: 0;
          }
        }
      }

      .price {
        position: absolute;
        right: 0;
        top: 30px;
        margin: 0;
      }

      .price-small {
        position: absolute;
        right: 0;
        top: 55px;
        margin: 0;
      }

      .service-quantity {
        position: absolute;
        right: 0;
        top: 90px;
        margin: 0;
      }

      .name {
        margin-top: 0;

        &.grid-view {
          display: none;
        }

        &.list-view {
          display: block;
        }
      }

      .symbol {
        &.grid-view {
          display: none;
        }

        &.list-view {
          display: block;
        }
      }

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  &.wyszukiwanie {
    .product-service {
      width: 19%;
    }
  }
}

.breadcrumb-wrapper {
  margin: 40px 0;

  .breadcrumb {
    border-radius: 0;
    padding: 0;
    background: none;
    margin-bottom: 0;

    .breadcrumb-item {
      font-size: 12px;
      font-weight: 500;

      a {
        color: @color1;

        svg {
          fill: @color1;
          height: 10px;
          width: 10px;
          transform: translateY(-1px);
          margin-right: 6px;
        }
      }
    }
  }
}

.pagination-nav {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  li {
    a {
      display: block;
      padding: 10px;
      color: #999;
      text-transform: uppercase;
    }

    &.active {
      a {
        font-weight: 700;
        color: @color1;
      }
    }
  }
}

nav.section-header {
  &.margin-bottom {
    margin-bottom: 70px;
  }

  .header-top-wrapper {
    background: #f1f1f1;
    border-bottom: 1px solid #e1e1e1;

    .header-top {
      padding: 13px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 13px;
        letter-spacing: -0.5px;
        color: #666;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          display: flex;

          a {
            font-size: 13px;
            letter-spacing: -0.5px;
            color: #555;
            display: flex;
            align-items: center;
            transition: all 0.4s;

            svg {
              height: 17px;
              width: 17px;
              margin-right: 6px;
              fill: @color1;
              transition: all 0.4s;
            }

            &:hover {
              color: @color1;

              svg {
                fill: @color1;
              }
            }
          }

          &:not(:last-child) {
            margin-right: 18px;

            &:after {
              content: "•";
              display: block;
              margin-left: 18px;
              color: @color1;
            }
          }
        }
      }

      .nav-button {
        padding: 10px;
        border: none;
        background: none;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        outline: none;
        display: none;

        .button-bar {
          height: 2px;
          width: 24px;
          background: @color1;
          border-radius: 10px;
          transition: all 0.4s;

          &:nth-child(2) {
            margin-top: 4px;
            width: 27px;
          }

          &:nth-child(3) {
            margin-top: 4px;
            width: 30px;
          }
        }

        &:hover,
        &:focus {
          .button-bar {
            width: 30px !important;
          }
        }
      }
    }
  }

  .header-management {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 30px 0;

    .management-logo {
      display: flex;
      align-items: center;

      img {
        height: 47px;
      }

      .logo-slogan {
        margin-left: 40px;
        color: @color1;
        font-weight: 300;
        font-size: 18px;

        strong {
          font-weight: 700;
        }
      }
    }

    .management-service {
      display: flex;
      align-items: center;

      li {
        a {
          display: flex;
          align-items: flex-start;
          transition: all 0.4s;
          position: relative;

          svg {
            height: 35px;
            width: 35px;
            fill: #444;
            transition: all 0.4s;
          }

          .name {
            display: block;
            font-size: 10px;
            color: #fff;
            background: @color1;
            padding: 0 5px;
            border-radius: 4px;
          }

          .cost-line {
            position: absolute;
            right: 4px;
            bottom: -1px;
            font-weight: 600;
            font-size: 12px;
            margin-left: 7px;
            color: #555;
            letter-spacing: -0.7px;

            small {
              letter-spacing: 0;
              font-size: 9px;
            }
          }

          &:hover {
            svg {
              fill: @color1;
            }
          }
        }

        &:nth-child(1) {
          a {
            svg {
              height: 31px;
              width: 31px;
            }
          }
        }

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }
  }

  .header-flexbox-wrapper {
    .header-flexbox {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flexbox-nav-wrapper {
        .nav-button-close {
          position: absolute;
          display: none;
          width: 100%;
          top: 20px;
          left: 0;

          .container {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          button {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            .button-bar {
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .flexbox-nav-inner {
          .flexbox-nav {
            display: flex;
            align-items: center;

            li {
              margin-right: 1px;

              a {
                color: #444;
                font-weight: 500;
                font-size: 14px;
                letter-spacing: 0.2px;
                border-radius: 8px;
                padding: 13px;
                display: block;
                font-family: "Montserrat", sans-serif;
                transition: all 0.4s;
              }

              &.active,
              &:hover {
                a {
                  background: @color1;
                  color: #fff;
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .flexbox-search-engine {
        border: 1px solid @color1;
        height: 45px;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.4s;

        input {
          width: 300px;
          height: 50px;
          padding: 10px 15px;
          border: none;
          margin: 0;
          transition: all 0.4s;

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: 13px;
            font-weight: 500;
            color: #999;
          }
        }

        .engine-select-wrapper {
          position: relative;
          min-width: 170px;
          border-left: 1px solid #e1e1e1;
          padding-left: 10px;

          select {
            cursor: pointer;
            padding: 14px 5px;
            border: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            font-size: 13px;
            appearance: none;
            outline: none;
            display: block;
            color: #666;
            width: 100%;
          }

          &:after {
            cursor: pointer;
            content: "";
            height: 10px;
            width: 10px;
            background: url("../img/pictures/down-chevron.svg") center;
            background-size: cover;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-40%);
          }
        }

        .btn-search {
          height: 50px;
          min-width: 50px;
          background: @color1;
          border: none;
          font-size: 14px;
          color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateX(1px);
          transition: background 0.4s;

          img {
            height: 14px;
          }

          &:hover {
            background: darken(@color1, 10%);
          }
        }
      }
    }
  }
}

section.section-welcome {
  border-top: 1px solid #e1e1e1;
  margin-top: 30px;
  background: url(../img/backgrounds/brickwall.png);
  position: relative;
  overflow: hidden;

  .welcome-image-1 {
    position: absolute;
    top: 0;
    left: -100px;
    height: 100%;
    opacity: 0.7;
    filter: grayscale(50%);
  }

  .welcome-image-2 {
    position: absolute;
    top: 0;
    right: -100px;
    height: 100%;
    opacity: 0.7;
    filter: grayscale(50%);
  }

  .welcome-inner {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 350px;

    h1 {
      color: darken(@color1, 5%);
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      font-size: 50px;
      line-height: 80px;

      strong {
        font-weight: 700;
      }
    }

    .btn {
      margin-top: 40px;
    }
  }
}

section.section-latest {
  padding-top: 75px;

  .section-heading-wrapper {
    display: flex;
    justify-content: space-between;

    .section-heading {
      &:nth-child(1) {
        width: 59%;
      }

      &:nth-child(2) {
        width: 38.5%;
      }
    }
  }

  .section-heading {
    text-align: center;

    h2 {
      padding-bottom: 10px;
      font-size: 22px;
      color: #444;
      font-weight: 600;
    }
  }

  .products-flexbox {
    .product-service {
      width: 18%;
    }

    .service-hidden {
      width: 18%;
    }

    .product-cta {
      margin-top: 30px;
      width: 38.5%;
      min-height: 300px;
      background: #f1f1f1;
      margin-bottom: 24px;
      position: relative;
      filter: grayscale(50%);

      .cta-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: @color1;
        opacity: 0.35;
        transition: all 0.4s;
      }

      .cta-inner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        .cta-background {
          opacity: 0.6;
        }
      }
    }
  }

  .btn-mobile {
    display: none;
  }
}

section.section-cta {
  padding-top: 75px;

  .section-heading {
    text-align: center;

    h2 {
      padding-bottom: 10px;
      font-size: 22px;
      color: #444;
      font-weight: 600;
    }
  }

  .cta-flexbox {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;

    .flexbox-service {
      background: #f1f1f1;
      position: relative;
      border: 1px solid #f1f1f1;
      display: flex;

      .service-image {
        width: 40%;
        padding-top: 40%;
      }

      .service-description {
        padding: 10px;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        h2 {
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          font-family: "Montserrat", sans-serif;
          color: #222;
          position: relative;
          transition: all 0.4s;

          &:after {
            content: "";
            height: 5px;
            width: 50px;
            margin-top: 10px;
            background: @color1;
            display: block;
          }
        }

        button {
          display: block;
        }
      }

      &:hover {
        h2 {
          color: @color1;
        }
      }
    }
  }
}

section.section-advantages {
  padding-top: 75px;

  .advantages-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .advantage-service {
      display: flex;
      align-items: center;
      width: 23%;
      padding: 10px;
      background: @color1;

      img {
        width: 40px;
        transition: all 0.4s;
      }

      p {
        width: 100%;
        padding: 0 10px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 16px;
        transition: color 0.4s;
      }

      &:hover {
        img {
          transform: translateY(-4px);
        }
      }
    }
  }
}

section.kategoria-section-content {
  padding: 0 0 75px 0;

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .flexbox-sidebar {
      width: 20%;
      position: relative;

      .heading {
        margin-bottom: 15px;

        p {
          transform: translateY(1px);
          font-size: 20px;
          font-weight: 500;
          color: #444;
          text-align: center;
        }
      }

      .nav-button {
        outline: none;
        padding: 10px;
        width: 100%;
        border: none;
        font-size: 11px;
        color: #fff;
        text-transform: uppercase;
        background: @color1;
        transition: all 0.3s;
        display: none;
        cursor: pointer;
        position: relative;

        &:hover {
          background: @color1;
        }
      }

      .category-nav-wrapper {
        position: relative;

        .nav-button-close {
          position: absolute;
          display: none;
          width: 100%;
          top: 20px;
          left: 0;

          .container {
            display: flex;
            justify-content: flex-end;

            &:before,
            &:after {
              content: none;
            }
          }

          button {
            outline: none;
            border: none;
            background: none;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .button-bar {
              display: block;
              position: absolute;
              width: 30px;
              height: 2px;
              background: #222;

              &:nth-child(1) {
                transform: rotate(45deg);
              }

              &:nth-child(2) {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .category-nav-inner {
          .category-nav {
            li {
              list-style: none;

              a {
                color: #444;
                padding: 5px 0;
                font-size: 16px;
                transition: all 0.4s;
                display: block;
                text-transform: lowercase;

                &:first-letter {
                  text-transform: uppercase;
                }

                &:hover {
                  color: @color1;
                }
              }

              &.active {
                > a {
                  font-weight: 700;
                  color: @color1;
                  padding-left: 0;

                  &:hover {
                  }
                }
              }

              &:not(:last-child) {
                padding-bottom: 4px;
                margin-bottom: 4px;
                border-bottom: 1px solid #e1e1e1;
              }

              ul {
                padding-left: 20px;
                padding-bottom: 10px;

                li {
                  padding-bottom: 0 !important;
                  border-bottom: none !important;
                  margin-bottom: 0 !important;

                  a {
                    padding: 5px 0;
                    font-size: 14px;
                    color: #777;
                  }
                }
              }
            }
          }

          .filter-nav {
            margin-top: 50px;

            .heading {
              &:not(:first-of-type) {
                margin-top: 50px;
              }
            }

            .range-slider {
              width: 100%;
              margin: auto;
              margin-top: 20px;
              text-align: center;
              position: relative;

              .slider-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                input[type="number"] {
                  border: none;
                  margin: 0 10px;
                  outline: none;
                  text-align: center;
                  font-size: 14px;
                  height: 30px;
                  border-bottom: 1px solid @color1;
                  width: 100%;
                  max-width: 65px;
                  -moz-appearance: textfield;
                }

                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }

                input[type="number"]:invalid,
                input[type="number"]:out-of-range {
                  border: 1px solid #ff6347;
                }

                span {
                  display: block;
                  min-width: 12px;
                }

                p {
                  font-size: 13px;
                }
              }
            }

            .buttons-wrapper {
              margin-top: 50px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .btn-clear {
                color: #444;
                border: none;
                font-size: 12px;
                font-weight: 600;
                background: none;
                cursor: pointer;
                padding: 0;
                text-transform: uppercase;
              }

              .btn-filter {
                padding: 0;
                border: none;
                background: @color1;
                font-size: 12px;
                font-weight: 600;
                border-radius: 4px;
                color: #fff;
                padding: 3px 10px;
                cursor: pointer;
                text-transform: uppercase;
                transition: all 0.3s;

                &:hover {
                  background: darken(@color1, 10%);
                }
              }
            }
          }
        }
      }
    }

    .flexbox-body {
      width: 75%;

      .body-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .heading-text {
          font-size: 13px;
          color: #777;
          position: relative;
          text-align: center;
        }

        .inner-wrapper {
          display: flex;
          align-items: center;

          .heading-grid {
            margin-right: 30px;
            display: flex;

            a,
            button {
              margin-right: 7px;
              padding: 0;
              border: none;
              background: #fff;
              outline: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              transition: all 0.4s;

              img {
                width: 15px;
                transition: all 0.4s;
              }

              &:last-child {
                margin-right: 0;
              }

              &.active {
                background: @color1;
              }
            }
          }
        }
      }

      .heading {
        margin-top: 60px;
        margin-bottom: 15px;
        text-align: center;

        p {
          padding-bottom: 10px;
          font-size: 22px;
          color: #444;
          font-weight: 600;
        }
      }

      .body-cta {
        margin-top: 40px;
        display: block;
        position: relative;
        transition: border-radius 0.4s;
        overflow: hidden;

        img {
          width: 100%;
        }

        .cta-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          text-align: right;
          padding: 30px;

          p {
            font-weight: 300;
            font-size: 24px;
            color: #fff;

            strong {
              font-size: 31px;
              font-weight: 700;
            }
          }
        }

        &:hover {
          border-radius: 10px;
        }
      }

      .body-more {
        margin-top: 65px;
        text-align: center;

        .btn {
          width: 400px;
          max-width: 100%;
        }
      }

      .body-product {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .product-gallery {
          width: 38%;

          img {
            width: 100%;
          }

          .lSAction {
            .lSPrev {
              width: 25px;
              height: 25px;
              background: url("../library/lightslider-master/dist/img/prev.svg")
                center;
              background-size: cover;
              margin-top: 0px;
              transform: translateY(-50%);
              left: 1px;
            }

            .lSNext {
              width: 25px;
              height: 25px;
              background: url("../library/lightslider-master/dist/img/prev.svg")
                center;
              background-size: cover;
              margin-top: 0px;
              transform: translateY(-50%) rotate(180deg);
              right: 1px;
            }
          }

          .lSGallery {
            margin-top: 23px !important;

            li {
              border: none;
              opacity: 0.6;
              transition: all 0.3s;

              &.active,
              &:hover {
                opacity: 1;
              }
            }
          }
        }

        .product-info {
          width: 55%;

          h2 {
            font-size: 36px;
            font-weight: 400;
            font-family: "Montserrat", sans-serif;
          }

          .code {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 300;

            strong {
              font-weight: 700;
              color: @color1;
            }
          }

          .description {
            margin-top: 20px;

            p {
              font-size: 14px;
            }
          }

          .symbol {
            margin-top: 20px;
            padding: 10px;
            background: #f9f9f9;

            p {
              font-size: 16px;
              font-weight: 300;

              strong {
                font-weight: 700;
                color: #999;
              }
            }

            ul {
              margin-top: 10px;
              margin-left: 66px;

              li {
                color: #999;
                font-weight: 700;
                font-size: 12.5px;

                &.list-heading {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }

          .features {
            margin-top: 20px;

            > * {
              &:not(:first-child) {
                margin-top: 10px;
              }
            }

            p {
              font-size: 16px;
              font-weight: 300;

              strong {
                font-weight: 700;
                color: #999;
              }
            }
          }

          .download-list {
            margin-top: 20px;

            li {
              a {
                display: block;
                background: lighten(#efefefef, 3%);
                padding: 8px 10px;
                font-size: 13px;
                border-left: 2px solid transparent;
                color: #333;

                &:hover {
                  border-color: @color2;
                  color: @color1;
                }
              }

              &.heading {
                margin-top: 0;
                text-align: left;
              }

              &:nth-child(even) {
                a {
                  background: #efefef;
                }
              }
            }
          }

          .price {
            margin-top: 40px;

            .price-netto {
              color: @color1;
              font-size: 40px;
              font-weight: 500;
              line-height: 30px;
              display: flex;
              align-items: flex-end;

              span {
                font-weight: 400;
                color: #999;
                font-size: 12px;
                line-height: 15px;
                padding-left: 10px;
              }
            }

            .price-brutto {
              margin-top: 10px;
              font-weight: 400;
              color: #999;
              font-size: 18px;
              line-height: 18px;
              padding-left: 3px;
            }

            .quantity-wrapper {
              margin-top: 20px;

              .wrapper-inner {
                display: flex;

                .input-number {
                  width: 70px;
                  padding: 0 12px;
                  text-align: center;
                  outline: none;
                  background: none;
                  border: none;
                  border-top: 1px solid #999;
                  border-bottom: 1px solid #999;
                }

                .input-number,
                .input-number-decrement,
                .input-number-increment {
                  height: 35px;
                  user-select: none;
                }

                .input-number-decrement,
                .input-number-increment {
                  min-width: 35px;
                  background: none;
                  color: #999;
                  text-align: center;
                  font-weight: 700;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: background 0.4s;
                  cursor: pointer;
                  border: 1px solid #999;
                  outline: none;

                  &:active {
                    background: @color1;
                    color: #fff;
                  }
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  margin: 0;
                }

                input[type="number"] {
                  -moz-appearance: textfield;
                }
              }

              .btn {
                margin-top: 20px;
              }
            }
          }
        }
      }

      .related {
        .product-service {
          width: 18%;
        }
      }
    }
  }
}

section.aktualnosci-section-content {
  padding-bottom: 50px;

  .section-heading {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 22px;
      color: #444;
      font-weight: 600;
    }

    .heading-categories {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .btn {
        margin: 5px;
      }
    }
  }

  .news-flexbox {
    margin-top: calc(~"-8% / 3");
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service {
      margin-top: calc(~"8% / 3");
      width: 23%;

      .service-image {
        padding-top: 63%;
        width: 100%;
        position: relative;
        border-radius: 2px;
        transition: all 0.4s;
      }

      .service-name {
        margin-top: 15px;

        .date {
          color: @color1;

          strong {
            margin-right: 10px;
          }
        }

        p {
          font-size: 15px;
          color: #333;
          font-weight: 600;
          font-family: "Montserrat", sans-serif;
          transition: all 0.4s;
        }
      }

      &:hover {
        .service-image {
          opacity: 0.8;
        }

        .service-name {
          p {
            color: @color1;
          }
        }
      }

      &.service-hidden {
        opacity: 0;
        visibility: hidden;
        margin: 0;
        padding: 0;
      }
    }
  }
}

section.static-page-section-content {
  padding: 0 0 70px 0;

  .content-heading {
    text-align: center;

    h2 {
      font-size: 36px;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
    }

    .date {
      margin-top: 15px;
      color: @color1;

      strong {
        margin-right: 10px;
      }
    }
  }

  .content-inner {
    width: 70%;
    margin: 40px auto 0;
    text-align: center;

    > * {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    p,
    li {
      font-size: 15px;
      line-height: 26px;
      color: #444;
    }

    ul,
    ol {
      padding-left: 17px;
    }
  }

  .content-offer-links {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .offer-link {
      position: relative;
      width: 24%;
      padding-top: 22%;
      margin: 7px 0;
      background: #f1f1f1;
      filter: grayscale(50%);

      .offer-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: @color1;
        opacity: 0.35;
        transition: all 0.4s;
      }

      .offer-name {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-default {
          font-size: 12px;
          padding: 5px;
          width: 100%;
          white-space: normal;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover {
        .offer-background {
          opacity: 0.6;
        }
      }
    }

    .box-hidden {
      width: 24%;
      visibility: hidden;
      opacity: 0;
    }
  }

  .media-flexbox {
    padding-top: 50px;
    margin-top: calc(-5% / 4);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-service-wrapper {
      margin-top: calc(5% / 4);
      border-radius: 2px;
      width: 19%;
      position: relative;
      overflow: hidden;

      .flexbox-service {
        width: 100%;
        padding-top: 66%;
        display: block;

        .service-inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            transition: all 0.3s;
          }
        }
      }

      &.service-hidden {
        margin-top: 0;
      }
    }
  }
}

.content-contact {
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;

  .contact-service-category {
    display: flex; 
    justify-content: flex-start; 
    align-items: center; 
    width: 100%; 
    height: 50px; 
    margin: 30px 0 -30px 0; 
    padding: 0; 

    h2 {
      font-size: 22px;
    }

  }

  .contact-service {
    .service-image {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      margin: 0 auto;
    }

    .name {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .job {
      margin-top: -10px;
      font-size: 12px;
    }

    .phone {
      margin-top: 3px;
      display: block;
      font-size: 10px;
      color: #fff;
      background: @color1;
      padding: 0 5px;
      border-radius: 4px;
    }

    &:not(:first-child) {
      margin-left: 50px;
    }
  }
}

section.section-popular {
  padding-bottom: 50px;
}

section.kontakt-section-content {
  padding: 0 0 70px 0;
  position: relative;

  .content-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-heading {
      white-space: nowrap;
      padding-right: 70px;

      h2 {
        color: #444;
        font-size: 22px;
        font-weight: 400;
      }

      h3 {
        margin-top: 8px;
        color: @color1;
        font-size: 45px;
        font-weight: 800;
      }

      address {
        margin-top: 20px;
        color: #666;
        font-size: 18px;
        font-weight: 400;

        small {
          margin-top: 20px;
          display: block;
          color: #999;
          margin-bottom: 7px;
        }

        a {
          font-weight: 700;
          color: @color1;
        }
      }

      form {
        margin-top: 25px;

        .input-service {
          margin-top: 15px;

          label {
            font-weight: 400;
            font-size: 12px;
            text-align: left;
            width: 100%;
          }

          input,
          textarea {
            width: 100%;
            display: block;
            border: none;
            border-radius: 5px;
            height: 45px;
            padding: 10px;
            background: #f2f2f2;
            transition: box-shadow 0.4s;

            &:focus {
              outline: none;
              box-shadow: 0px 0px 2px 0px #2ca9cd;
            }

            &.warning {
              box-shadow: 0px 0px 2px 0px #b31616;
            }
          }

          textarea {
            height: 150px;
          }
        }

        .btn {
          margin-top: 20px;
        }
      }
    }

    .content-contact {
      width: 100%;
      margin-top: -20px;
      justify-content: space-between;

      .contact-service {
        margin-top: 30px;
        width: 24%;
        margin-left: 0;

        .name {
          font-size: 15px;
        }
      }
    }
  }

  .content-iframe {
    margin-top: 60px;
    width: 100%;
    height: 400px;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: none;
    }
  }
}

section.section-footer {
  border-top: 1px solid #e1e1e1;
  background: #f1f1f1;
  position: relative;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .flexbox-service {
      margin-top: 35px;

      &.service-about {
        .service-logo {
          img {
            height: 47px;
          }
        }

        .service-description {
          margin-top: 20px;

          p {
            color: #444;
            font-size: 14px;
          }
        }
      }

      ul {
        li {
          margin-top: 5px;

          &.heading {
            margin-top: 0;
            padding-bottom: 10px;
            font-size: 18px;
            color: #444;
          }

          a {
            font-size: 13px;
            color: #444;
            transition: all 0.3s;

            b {
              font-size: 16px;
            }

            span {
              font-size: 10px;
            }

            &:hover {
              color: @color1;
            }
          }

          p {
            font-size: 12px;
            color: #444;

            b {
              font-weight: 400;
              font-size: 14px;
            }

            span {
              font-size: 10px;
            }
          }
        }
      }

      .list-social {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-right: 20px;

          a {
            img {
              height: 25px;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .alert {
    position: relative;
    background: none;
    border: none;
    border-radius: 0;
    margin-top: 35px;
    padding: 0;
    background: #f1f1f1;

    p {
      font-size: 14px;
      color: #444;
      font-weight: 300;

      a {
        color: @color1;
        cursor: pointer;
      }
    }
  }

  .footer-credits-wrapper {
    margin-top: 35px;
    padding: 15px 0;
    border-top: 1px solid #e1e1e1;
    background: #e9e9e9;

    .footer-credits {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      p {
        font-size: 14px;
        color: #444;
        font-weight: 300;

        a {
          color: @color1;
        }
      }

      .footer-payment {
        display: flex;
        align-items: center;

        p {
          margin-right: 15px;
        }

        img {
          margin-right: 10px;
          height: 19px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
