// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {
  .container {
    max-width: 1260px;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  nav.section-header {
    .header-flexbox-wrapper {
      .header-flexbox {
        .flexbox-search-engine {
          input {
            width: 200px;
          }

          .engine-select-wrapper {
            min-width: 100px;

            select {
              padding: 14px 25px 14px 5px;
            }
          }
        }

        .flexbox-nav-wrapper {
          .flexbox-nav-inner {
            .flexbox-nav {
              li {
                a {
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  section.section-cta {
    .cta-flexbox {
      .flexbox-service {
        .service-description {
          h2 {
            font-size: 20px;
          }

          .btn {
            padding: 8px 15px;
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  nav.section-header {
    .header-top-wrapper {
      .header-top {
        flex-direction: column;

        ul:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

    .header-flexbox-wrapper {
      .header-flexbox {
        flex-direction: column;

        .flexbox-search-engine {
          margin-bottom: 25px;
        }
      }
    }

    .header-management {
      flex-wrap: wrap;
      justify-content: center;

      .management-logo {
        order: 1;
        margin-bottom: 20px;
      }

      .management-search-engine {
        margin-top: 25px;
        order: 3;
        width: 100%;
      }

      .management-service {
        order: 2;
      }
    }
  }

  .select-wrapper {
    select {
      padding: 5px 50px 5px 10px;
    }
  }

  .products-flexbox {
    &.wyszukiwanie {
      .product-service {
        width: 31%;
      }
    }
    .product-service {
      .btn-more {
        margin-top: 0;
      }
    }
  }

  section.section-welcome {
    .welcome-image-1 {
      left: -130px;
    }

    .welcome-image-2 {
      right: -130px;
    }
  }

  section.section-latest {
    .section-heading-wrapper {
      .section-heading {
        &:nth-child(1) {
          width: 100%;
          text-align: left;
        }

        &:nth-of-type(2) {
          display: none;
        }
      }
    }

    .products-flexbox {
      .product-service {
        width: 31%;
        margin-top: 40px;
      }

      .service-hidden {
        width: 31%;
      }

      .product-cta {
        display: none;
      }
    }

    .btn-mobile {
      display: inline-block;
      margin-top: 30px;
    }
  }

  section.section-cta {
    .cta-flexbox {
      grid-gap: 12px;

      .flexbox-service {
        .service-description {
          width: 65%;

          h2 {
            font-size: 16px;

            &::after {
              margin-top: 3px;
              height: 4px;
            }
          }

          .btn {
            padding: 8px 6px;
            font-size: 12px;
          }
        }

        .service-image {
          width: 35%;
        }
      }
    }
  }

  section.section-advantages {
    .advantages-flexbox {
      flex-wrap: wrap;

      .advantage-service {
        flex-direction: column;

        p {
          font-size: 13px;
          padding: 0;
          margin-top: 5px;
        }
      }
    }
  }

  section.static-page-section-content {
    .content-offer-links {
      .offer-link {
        width: 49%;
        padding-top: 40%;

        .btn-default {
          min-height: 50px;
        }
      }

      .box-hidden {
        width: 49%;
      }
    }
  }

  section.kategoria-section-content {
    .content-flexbox {
      .flexbox-body {
        .related {
          .product-service {
            width: 32%;
          }
        }
        .products-flexbox {
          &.grid-list {
            .product-service {
              width: 31%;
              padding: 0;
              .name {
                font-size: 12.5px;
              }
              .symbol {
                font-size: 10.5px;
              }
              .footer {
                margin-top: 0;
              }
            }
          }
          .product-service {
            width: 31%;
          }
        }
      }
    }
  }

  section.kontakt-section-content {
    .content-flexbox {
      .content-contact {
        display: flex;
        flex-wrap: wrap;

        .contact-service {
          width: 47%;
        }
      }
    }
  }

  section.section-footer {
    .footer-flexbox {
      .flexbox-service:not(:first-of-type) {
        margin-left: 15px;

        ul {
          li.heading {
            font-size: 14px;
          }
        }

        .list-social {
          justify-content: flex-start;
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  nav.section-header {
    .header-top-wrapper {
      .header-top {
        justify-content: center;

        ul {
          li:not(:last-child) {
            margin-right: 15px;
          }
        }

        p {
          display: none;
        }
      }
    }

    .header-management {
      .management-logo {
        .logo-slogan {
          margin-left: 30px;
          font-size: 16px;
        }
      }
    }
  }

  .products-flexbox {
    &.grid-list {
      .product-service {
        align-items: center;
        .badge-wrapper {
          .badge {
            width: 90px;
            height: 30px;
            font-size: 14px;
            transform: translateY(-105%);
          }
        }

        header {
          min-width: 40%;
        }

        .footer {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 0;
        }

        .price,
        .price-small {
          position: static;
        }

        .btn-more {
          margin-top: 15px;
        }
      }
    }
  }

  section.section-welcome {
    .welcome-image-1 {
      left: -250px;
    }

    .welcome-image-2 {
      right: -220px;
    }
  }

  section.section-cta {
    .cta-flexbox {
      grid-template-columns: 1fr;
      grid-gap: 30px;

      .flexbox-service {
        .service-description {
          width: 60%;

          h2 {
            font-size: 22px;
          }

          .btn {
            padding: 8px 15px;
            font-size: 16px;
          }
        }

        .service-image {
          width: 40%;
        }
      }
    }
  }

  section.section-latest {
    .products-flexbox {
      .product-service {
        width: 47%;
        margin-top: 30px;

        &:nth-of-type(3) {
          display: none;
        }
      }
    }
  }

  section.section-advantages {
    padding-top: 50px;

    .advantages-flexbox {
      flex-wrap: wrap;

      .advantage-service {
        width: 48%;
        margin-top: 20px;

        p {
          font-size: 13px;
          padding: 0;
          margin-top: 5px;
        }
      }
    }
  }

  section.kategoria-section-content {
    .content-flexbox {
      flex-direction: column;

      .flexbox-body {
        width: 100%;

        .body-product {
          flex-direction: column;
          align-items: center;

          .product-gallery {
            order: 2;
            width: 60%;
            padding-top: 30px;
          }

          .product-info {
            width: 100%;
            text-align: center;

            .download-list {
              li.heading {
                text-align: center;
              }
            }

            table {
              width: 100% !important;

              tr {
                display: flex;
                flex-direction: column;
                align-items: center;

                td {
                  height: auto !important;
                  width: 100% !important;
                  display: inline-block;

                  &:first-of-type {
                    font-weight: 600;
                    margin-bottom: 5px;
                  }
                }
              }
            }

            .price {
              display: flex;
              flex-direction: column;
              align-items: center;

              .quantity-wrapper {
                .wrapper-inner {
                  justify-content: center;
                }
              }
            }
          }
        }

        .related {
          .product-service {
            &:nth-of-type(2) {
              display: none;
            }
          }
        }

        .products-flexbox {
          .product-service {
            width: 47%;
          }
        }
      }

      .flexbox-sidebar {
        width: 100%;

        .category-nav-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #fff;
          z-index: 555;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;

          .nav-button-close {
            display: block;
          }

          .category-nav-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-height: 100%;
            width: 100%;
            padding: 50px;

            .category-nav {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;

              li {
                text-align: center;

                a {
                  font-size: 18px;
                  padding: 8px 0;
                }

                ul {
                  padding-left: 0;
                }
              }
            }

            .filter-nav {
              min-width: 40%;

              .buttons-wrapper {
                .btn-filter {
                  padding: 7px 20px;
                }
              }
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
          }
        }

        .nav-button {
          display: flex;
          justify-content: center;
          padding: 15px 0;
          margin-bottom: 40px;
        }
      }
    }
  }

  section.aktualnosci-section-content {
    padding-bottom: 50px;

    .news-flexbox {
      .flexbox-service {
        width: 31%;

        .service-name {
          p {
            font-size: 13px;
          }
        }
      }
    }
  }

  section.static-page-section-content {
    .content-contact {
      .contact-service {
        width: 26%;
      }
    }

    .media-flexbox {
      .flexbox-service-wrapper {
        width: 32%;
      }
    }
  }

  section.kontakt-section-content {
    .content-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-heading {
        padding-right: 0;
        text-align: center;
      }

      .content-contact {
        padding-top: 30px;

        .contact-service {
          width: 30%;

          .name {
            font-size: 14px;
          }
        }
      }
    }
  }

  section.section-footer {
    .footer-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-service:not(:first-of-type) {
        margin-left: 0;
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        ul {
          li.heading {
            font-size: 20px;
          }

          li {
            a {
              font-size: 16px;
            }
          }
        }

        .list-social {
          justify-content: flex-end;
        }
      }

      .service-about {
        .service-description {
          p {
            text-align: center;
          }
        }
      }
    }

    .alert {
      p {
        text-align: center;
      }
    }

    .footer-credits-wrapper {
      .footer-credits,
      .footer-payment {
        flex-direction: column;

        p {
          text-align: center;
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  nav.section-header {
    .header-top-wrapper {
      .header-top {
        ul:first-of-type {
          display: none;
        }

        ul {
          li:not(:last-child) {
            margin-right: 10px;

            &:after {
              margin-left: 5px;
            }
          }
        }

        .nav-button {
          display: flex;
          margin-right: -10px;
        }
      }
    }

    .header-management {
      padding: 15px 0;

      .management-logo {
        .logo-slogan {
          display: none;
        }

        img {
          margin-right: 15px;
        }
      }

      .management-service {
        width: 100%;
        justify-content: space-around;

        li:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .header-flexbox-wrapper {
      .header-flexbox {
        .flexbox-search-engine {
          margin-bottom: 0;

          input {
            width: 155px;
          }
        }

        .flexbox-nav-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #fff;
          z-index: 555;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;

          .nav-button-close {
            display: block;
          }

          .flexbox-nav-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 100%;
            padding: 50px;
            margin-right: 0;

            .flexbox-nav {
              flex-direction: column;
              align-items: center;

              li {
                a {
                  padding: 20px;
                  font-size: 16px;
                }
              }
            }
          }

          &.active {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .breadcrumb-wrapper {
    display: none;
  }

  .select-wrapper {
    select {
      width: 150px;
      padding: 5px 30px 5px 10px;
    }
  }

  .products-flexbox {
    &.wyszukiwanie {
      .product-service {
        width: 47%;
      }
    }
  }

  section.section-welcome {
    .welcome-image-1 {
      left: -245px;
    }

    .welcome-image-2 {
      right: -230px;
    }

    .welcome-inner {
      h1 {
        font-size: 35px;
        line-height: 50px;
      }
    }
  }

  section.kategoria-section-content {
    padding: 50px 0;

    .content-flexbox {
      .flexbox-body {
        .body-heading {
          .inner-wrapper {
            .heading-grid {
              margin-right: 15px;
            }
          }

          .heading-text {
            font-size: 10px;
          }
        }

        .body-product {
          .product-gallery {
            width: 100%;
          }

          .product-info {
            h2 {
              font-size: 32px;
            }
          }
        }

        .related {
          justify-content: center;

          .badge-wrapper {
            .badge {
              width: 90px;
              height: 30px;
              font-size: 14px;
              transform: translateY(-105%);
            }
          }

          .product-service {
            width: 80%;
            margin-top: 40px;

            &:nth-of-type(5) {
              display: none;
            }

            .btn-more {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  section.aktualnosci-section-content {
    padding: 50px 0;

    .news-flexbox {
      .flexbox-service {
        width: 47%;
      }
    }
  }

  section.static-page-section-content {
    padding: 50px 0;

    .content-heading {
      h2 {
        font-size: 32px;
      }
    }

    .content-inner {
      width: 100%;
    }

    .media-flexbox {
      padding-top: 20px;

      .flexbox-service-wrapper {
        width: 48%;
        margin-top: 15px;

        &:nth-of-type(5) {
          display: none;
        }
      }
    }

    .content-contact {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-service {
        width: 40%;
      }

      .contact-service:not(:first-child) {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }

  section.section-popular {
    padding: 30px 0 50px 0;
  }

  section.kategoria-section-heading {
    h1 {
      font-size: 28px;
    }
  }

  section.kontakt-section-content {
    padding: 50px 0;
  }
}

// Extra small devices (portrait phones, less than 500px)
@media (max-width: 500px) {
  .products-flexbox {
    &.grid-list {
      .product-service {
        header {
          min-width: 50%;
        }

        .footer {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .btn-more {
          margin-top: 7px;
        }
      }
    }

    &.wyszukiwanie {
      justify-content: center;

      .product-service {
        margin-top: 50px;
        width: 80%;

        .badge-wrapper {
          .badge {
            width: 90px;
            height: 30px;
            font-size: 14px;
            transform: translateY(-105%);
          }
        }

        .btn-more {
          font-size: 14px;
        }
      }
    }
  }

  section.section-welcome {
    .welcome-image-1 {
      left: -265px;
      opacity: 0.5;
    }

    .welcome-image-2 {
      right: -250px;
      opacity: 0.5;
    }

    .welcome-inner {
      h1 {
        font-size: 28px;
        line-height: 40px;
      }
    }
  }

  section.section-latest {
    .products-flexbox {
      align-items: center;
      justify-content: center;

      .product-service {
        width: 80%;
        margin-top: 40px;

        .badge-wrapper {
          .badge {
            width: 90px;
            height: 30px;
            font-size: 14px;
            transform: translateY(-105%);
          }
        }

        .btn-more {
          font-size: 14px;
        }
      }
    }

    .btn-mobile {
      margin-top: 0;
      transform: translate(12%, 100%);
    }
  }

  section.kategoria-section-content {
    .content-flexbox {
      .flexbox-body {
        .products-flexbox {
          justify-content: center;
          &.grid-list {
            .product-service {
              padding: 10px;
              .btn-more {
                font-size: 10px;
              }
            }
          }
          .product-service {
            width: 80%;
            .btn-more {
              font-size: 14px;
            }
          }
        }
      }

      .flexbox-sidebar {
        .category-nav-wrapper {
          .category-nav-inner {
            .filter-nav {
              min-width: 70%;
            }
          }
        }
      }
    }
  }

  section.kontakt-section-content {
    .content-flexbox {
      .content-contact {
        flex-direction: column;
        align-items: center;

        .contact-service {
          width: 45%;

          .name {
            font-size: 16px;
          }
        }
      }
    }
  }
}
