@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXBiEJow.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBiEJow.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXBiEJow.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQBiEJow.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QBiEJow.ttf) format('truetype');
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v18/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQBiEJow.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Hw3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Hw3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu173w3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM73w3aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC73w3aX8.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .btn {
  padding: 8px 30px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  text-transform: uppercase;
  transition: background 0.4s;
}
body .btn.btn-default {
  background: #00a651;
  border: 1px solid #00a651;
  color: #fff;
}
body .btn.btn-default:hover {
  background: #007338;
  border: 1px solid #007338;
}
body .btn.btn-border {
  background: #fff;
  border: 1px solid #00a651;
  color: #00a651;
}
body .btn.btn-border:hover {
  color: #fff;
  background: #00a651;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
.select-wrapper {
  position: relative;
  display: flex;
  background: #f1f1f1;
}
.select-wrapper:before {
  content: "";
  width: 2px;
  height: 30px;
  background: #00a651;
}
.select-wrapper select {
  cursor: pointer;
  padding: 5px 100px 5px 10px;
  border: none;
  background: #f1f1f1;
  height: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 13px;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
}
.select-wrapper:after {
  cursor: pointer;
  content: "";
  height: 10px;
  width: 10px;
  background: url("../img/pictures/down-chevron.svg") center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;
}
.checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: none;
  color: #414141;
  font-weight: 300;
  text-align: left;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box:before {
  content: "";
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url("../img/pictures/checkbox.svg") no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: url("../img/pictures/checkbox-checked.svg") no-repeat;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.products-flexbox .product-service {
  margin-top: 30px;
  border: 1px solid #f1f1f1;
  background: #f1f1f1;
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.products-flexbox .product-service .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 0;
  left: -1px;
  width: calc(100% + 2px);
  display: flex;
  justify-content: space-between;
}
.products-flexbox .product-service .badge-wrapper .badge {
  width: 70px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 9px;
  border-radius: 2px 2px 0 0;
  transform: translateY(-100%);
  background: #f1f1f1;
}
.products-flexbox .product-service .badge-wrapper .badge.promotion {
  border-top: 1px solid tomato;
  color: tomato;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.new {
  border-top: 1px solid olivedrab;
  color: olivedrab;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.bestseller {
  border-top: 1px solid dodgerblue;
  color: dodgerblue;
  display: none;
}
.products-flexbox .product-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  display: block;
  transition: all 0.4s;
  background: #fff;
  position: relative;
}
.products-flexbox .product-service .service-image-wrapper .service-image {
  text-align: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;
}
.products-flexbox .product-service .service-image-wrapper .service-image img {
  width: 100%;
}
.products-flexbox .product-service .service-image-wrapper.active:after {
  content: "";
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: #d30e0e;
  box-shadow: 0px 0px 8px 0px rgba(211, 14, 14, 0.4);
}
.products-flexbox .product-service .service-quantity {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.products-flexbox .product-service .service-quantity p {
  font-weight: 700;
  font-size: 12px;
}
.products-flexbox .product-service .service-quantity .quantity-inner {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.products-flexbox .product-service .service-quantity input {
  width: 55px;
  height: 25px;
  border-radius: 4px 0 0 4px;
  border: 1px solid #00a651;
  border-right: none;
  font-size: 14px;
  outline: none;
  padding-left: 10px;
  transition: all 0.4s;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}
.products-flexbox .product-service .service-quantity input.active {
  border: 1px solid #d30e0e;
  border-right: none;
}
.products-flexbox .product-service .service-quantity button {
  height: 25px;
  width: 25px;
  border-radius: 0 4px 4px 0;
  border: none;
  background: #00a651;
  color: #fff;
  cursor: pointer;
  transition: background 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-flexbox .product-service .service-quantity button img {
  height: 14px;
}
.products-flexbox .product-service .service-quantity button:hover {
  background: #007338;
}
.products-flexbox .product-service .name {
  margin-top: 15px;
  padding: 0 5px;
  color: #222;
  font-weight: 600;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.products-flexbox .product-service .name.list-view {
  display: none;
}
.products-flexbox .product-service .symbol {
  margin-top: 5px;
  padding: 0 5px;
  font-size: 12px;
  color: #999;
}
.products-flexbox .product-service .symbol strong {
  color: #00a651;
}
.products-flexbox .product-service .symbol .lack {
  color: tomato;
}
.products-flexbox .product-service .symbol.list-view {
  display: none;
}
.products-flexbox .product-service .price {
  margin-top: 10px;
  color: #00a651;
  padding: 0 5px;
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 600;
}
.products-flexbox .product-service .price small {
  font-size: 18px;
  font-weight: 600;
  color: #444;
  margin-right: 5px;
  position: relative;
  display: none;
}
.products-flexbox .product-service .price small:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #999;
  transform: rotate(-10deg);
}
.products-flexbox .product-service .price span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
}
.products-flexbox .product-service .price-small {
  padding: 0 5px;
  font-size: 12px;
  color: #999;
}
.products-flexbox .product-service .type {
  padding: 0 5px;
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.products-flexbox .product-service .type strong {
  color: #00a651;
}
.products-flexbox .product-service .footer-quantity {
  margin-top: 15px;
  padding: 0 5px;
  display: flex;
}
.products-flexbox .product-service .footer-quantity p {
  font-size: 11px;
  color: #333;
}
.products-flexbox .product-service .footer-quantity p:nth-child(2) {
  margin-left: 7px;
  font-weight: 700;
  color: #00a651;
}
.products-flexbox .product-service .btn-more {
  margin-top: 17px;
  text-align: center;
  background: #fff;
  margin-left: -1px;
  transform: translateY(2px);
  width: calc(100% + 2px);
  padding: 5px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #444;
  display: block;
  border: none;
  transition: all 0.4s;
}
.products-flexbox .product-service.promotion .promotion {
  display: block !important;
}
.products-flexbox .product-service.promotion .price {
  color: tomato;
}
.products-flexbox .product-service.promotion .price small {
  display: inline-block;
}
.products-flexbox .product-service.new .new {
  display: block !important;
}
.products-flexbox .product-service.bestseller .bestseller {
  display: block !important;
}
.products-flexbox .product-service:hover .name {
  color: #00a651;
}
.products-flexbox .product-service:hover .btn-more {
  background: #00a651;
  color: #fff;
}
.products-flexbox .product-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
  width: 23%;
}
.products-flexbox .service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.products-flexbox.grid-list .product-service {
  width: 100% !important;
  flex-direction: row;
  justify-content: flex-start;
}
.products-flexbox.grid-list .product-service header {
  min-width: 17%;
  margin-right: 15px;
}
.products-flexbox.grid-list .product-service .footer {
  margin-top: 10px;
}
.products-flexbox.grid-list .product-service .badge-wrapper {
  right: auto;
  left: 0;
  top: 10px;
}
.products-flexbox.grid-list .product-service .service-image-wrapper.active:after {
  left: auto;
  right: 0;
}
.products-flexbox.grid-list .product-service .price {
  position: absolute;
  right: 0;
  top: 30px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .price-small {
  position: absolute;
  right: 0;
  top: 55px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .service-quantity {
  position: absolute;
  right: 0;
  top: 90px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .name {
  margin-top: 0;
}
.products-flexbox.grid-list .product-service .name.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .name.list-view {
  display: block;
}
.products-flexbox.grid-list .product-service .symbol.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .symbol.list-view {
  display: block;
}
.products-flexbox.grid-list .product-service:not(:first-child) {
  margin-top: 30px;
}
.products-flexbox.wyszukiwanie .product-service {
  width: 19%;
}
.breadcrumb-wrapper {
  margin: 40px 0;
}
.breadcrumb-wrapper .breadcrumb {
  border-radius: 0;
  padding: 0;
  background: none;
  margin-bottom: 0;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item {
  font-size: 12px;
  font-weight: 500;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
  color: #00a651;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a svg {
  fill: #00a651;
  height: 10px;
  width: 10px;
  transform: translateY(-1px);
  margin-right: 6px;
}
.pagination-nav {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagination-nav li a {
  display: block;
  padding: 10px;
  color: #999;
  text-transform: uppercase;
}
.pagination-nav li.active a {
  font-weight: 700;
  color: #00a651;
}
nav.section-header.margin-bottom {
  margin-bottom: 70px;
}
nav.section-header .header-top-wrapper {
  background: #f1f1f1;
  border-bottom: 1px solid #e1e1e1;
}
nav.section-header .header-top-wrapper .header-top {
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-top-wrapper .header-top p {
  font-size: 13px;
  letter-spacing: -0.5px;
  color: #666;
}
nav.section-header .header-top-wrapper .header-top ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-top-wrapper .header-top ul li {
  display: flex;
}
nav.section-header .header-top-wrapper .header-top ul li a {
  font-size: 13px;
  letter-spacing: -0.5px;
  color: #555;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
nav.section-header .header-top-wrapper .header-top ul li a svg {
  height: 17px;
  width: 17px;
  margin-right: 6px;
  fill: #00a651;
  transition: all 0.4s;
}
nav.section-header .header-top-wrapper .header-top ul li a:hover {
  color: #00a651;
}
nav.section-header .header-top-wrapper .header-top ul li a:hover svg {
  fill: #00a651;
}
nav.section-header .header-top-wrapper .header-top ul li:not(:last-child) {
  margin-right: 18px;
}
nav.section-header .header-top-wrapper .header-top ul li:not(:last-child):after {
  content: "•";
  display: block;
  margin-left: 18px;
  color: #00a651;
}
nav.section-header .header-top-wrapper .header-top .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-top-wrapper .header-top .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #00a651;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-top-wrapper .header-top .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-top-wrapper .header-top .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-top-wrapper .header-top .nav-button:hover .button-bar,
nav.section-header .header-top-wrapper .header-top .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header .header-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 30px 0;
}
nav.section-header .header-management .management-logo {
  display: flex;
  align-items: center;
}
nav.section-header .header-management .management-logo img {
  height: 47px;
}
nav.section-header .header-management .management-logo .logo-slogan {
  margin-left: 40px;
  color: #00a651;
  font-weight: 300;
  font-size: 18px;
}
nav.section-header .header-management .management-logo .logo-slogan strong {
  font-weight: 700;
}
nav.section-header .header-management .management-service {
  display: flex;
  align-items: center;
}
nav.section-header .header-management .management-service li a {
  display: flex;
  align-items: flex-start;
  transition: all 0.4s;
  position: relative;
}
nav.section-header .header-management .management-service li a svg {
  height: 35px;
  width: 35px;
  fill: #444;
  transition: all 0.4s;
}
nav.section-header .header-management .management-service li a .name {
  display: block;
  font-size: 10px;
  color: #fff;
  background: #00a651;
  padding: 0 5px;
  border-radius: 4px;
}
nav.section-header .header-management .management-service li a .cost-line {
  position: absolute;
  right: 4px;
  bottom: -1px;
  font-weight: 600;
  font-size: 12px;
  margin-left: 7px;
  color: #555;
  letter-spacing: -0.7px;
}
nav.section-header .header-management .management-service li a .cost-line small {
  letter-spacing: 0;
  font-size: 9px;
}
nav.section-header .header-management .management-service li a:hover svg {
  fill: #00a651;
}
nav.section-header .header-management .management-service li:nth-child(1) a svg {
  height: 31px;
  width: 31px;
}
nav.section-header .header-management .management-service li:not(:last-child) {
  margin-right: 40px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  margin-right: 1px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #444;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
  border-radius: 8px;
  padding: 13px;
  display: block;
  font-family: "Montserrat", sans-serif;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.active a,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
  background: #00a651;
  color: #fff;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine {
  border: 1px solid #00a651;
  height: 45px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine input {
  width: 300px;
  height: 50px;
  padding: 10px 15px;
  border: none;
  margin: 0;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine input:focus {
  outline: none;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine input::placeholder {
  font-size: 13px;
  font-weight: 500;
  color: #999;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .engine-select-wrapper {
  position: relative;
  min-width: 170px;
  border-left: 1px solid #e1e1e1;
  padding-left: 10px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .engine-select-wrapper select {
  cursor: pointer;
  padding: 14px 5px;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 13px;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .engine-select-wrapper:after {
  cursor: pointer;
  content: "";
  height: 10px;
  width: 10px;
  background: url("../img/pictures/down-chevron.svg") center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-40%);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .btn-search {
  height: 50px;
  min-width: 50px;
  background: #00a651;
  border: none;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(1px);
  transition: background 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .btn-search img {
  height: 14px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .btn-search:hover {
  background: #007338;
}
section.section-welcome {
  border-top: 1px solid #e1e1e1;
  margin-top: 30px;
  background: url(../img/backgrounds/brickwall.png);
  position: relative;
  overflow: hidden;
}
section.section-welcome .welcome-image-1 {
  position: absolute;
  top: 0;
  left: -100px;
  height: 100%;
  opacity: 0.7;
  filter: grayscale(50%);
}
section.section-welcome .welcome-image-2 {
  position: absolute;
  top: 0;
  right: -100px;
  height: 100%;
  opacity: 0.7;
  filter: grayscale(50%);
}
section.section-welcome .welcome-inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 350px;
}
section.section-welcome .welcome-inner h1 {
  color: #008d45;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 50px;
  line-height: 80px;
}
section.section-welcome .welcome-inner h1 strong {
  font-weight: 700;
}
section.section-welcome .welcome-inner .btn {
  margin-top: 40px;
}
section.section-latest {
  padding-top: 75px;
}
section.section-latest .section-heading-wrapper {
  display: flex;
  justify-content: space-between;
}
section.section-latest .section-heading-wrapper .section-heading:nth-child(1) {
  width: 59%;
}
section.section-latest .section-heading-wrapper .section-heading:nth-child(2) {
  width: 38.5%;
}
section.section-latest .section-heading {
  text-align: center;
}
section.section-latest .section-heading h2 {
  padding-bottom: 10px;
  font-size: 22px;
  color: #444;
  font-weight: 600;
}
section.section-latest .products-flexbox .product-service {
  width: 18%;
}
section.section-latest .products-flexbox .service-hidden {
  width: 18%;
}
section.section-latest .products-flexbox .product-cta {
  margin-top: 30px;
  width: 38.5%;
  min-height: 300px;
  background: #f1f1f1;
  margin-bottom: 24px;
  position: relative;
  filter: grayscale(50%);
}
section.section-latest .products-flexbox .product-cta .cta-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00a651;
  opacity: 0.35;
  transition: all 0.4s;
}
section.section-latest .products-flexbox .product-cta .cta-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-latest .products-flexbox .product-cta:hover .cta-background {
  opacity: 0.6;
}
section.section-latest .btn-mobile {
  display: none;
}
section.section-cta {
  padding-top: 75px;
}
section.section-cta .section-heading {
  text-align: center;
}
section.section-cta .section-heading h2 {
  padding-bottom: 10px;
  font-size: 22px;
  color: #444;
  font-weight: 600;
}
section.section-cta .cta-flexbox {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}
section.section-cta .cta-flexbox .flexbox-service {
  background: #f1f1f1;
  position: relative;
  border: 1px solid #f1f1f1;
  display: flex;
}
section.section-cta .cta-flexbox .flexbox-service .service-image {
  width: 40%;
  padding-top: 40%;
}
section.section-cta .cta-flexbox .flexbox-service .service-description {
  padding: 10px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
section.section-cta .cta-flexbox .flexbox-service .service-description h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  color: #222;
  position: relative;
  transition: all 0.4s;
}
section.section-cta .cta-flexbox .flexbox-service .service-description h2:after {
  content: "";
  height: 5px;
  width: 50px;
  margin-top: 10px;
  background: #00a651;
  display: block;
}
section.section-cta .cta-flexbox .flexbox-service .service-description button {
  display: block;
}
section.section-cta .cta-flexbox .flexbox-service:hover h2 {
  color: #00a651;
}
section.section-advantages {
  padding-top: 75px;
}
section.section-advantages .advantages-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-advantages .advantages-flexbox .advantage-service {
  display: flex;
  align-items: center;
  width: 23%;
  padding: 10px;
  background: #00a651;
}
section.section-advantages .advantages-flexbox .advantage-service img {
  width: 40px;
  transition: all 0.4s;
}
section.section-advantages .advantages-flexbox .advantage-service p {
  width: 100%;
  padding: 0 10px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  line-height: 16px;
  transition: color 0.4s;
}
section.section-advantages .advantages-flexbox .advantage-service:hover img {
  transform: translateY(-4px);
}
section.kategoria-section-content {
  padding: 0 0 75px 0;
}
section.kategoria-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar {
  width: 20%;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
  margin-bottom: 15px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading p {
  transform: translateY(1px);
  font-size: 20px;
  font-weight: 500;
  color: #444;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
  outline: none;
  padding: 10px;
  width: 100%;
  border: none;
  font-size: 11px;
  color: #fff;
  text-transform: uppercase;
  background: #00a651;
  transition: all 0.3s;
  display: none;
  cursor: pointer;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button:hover {
  background: #00a651;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:before,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:after {
  content: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
  list-style: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
  color: #444;
  padding: 5px 0;
  font-size: 16px;
  transition: all 0.4s;
  display: block;
  text-transform: lowercase;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a:first-letter {
  text-transform: uppercase;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a:hover {
  color: #00a651;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li.active > a {
  font-weight: 700;
  color: #00a651;
  padding-left: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li:not(:last-child) {
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #e1e1e1;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
  padding-left: 20px;
  padding-bottom: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li {
  padding-bottom: 0 !important;
  border-bottom: none !important;
  margin-bottom: 0 !important;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li a {
  padding: 5px 0;
  font-size: 14px;
  color: #777;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav {
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .heading:not(:first-of-type) {
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  text-align: center;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"] {
  border: none;
  margin: 0 10px;
  outline: none;
  text-align: center;
  font-size: 14px;
  height: 30px;
  border-bottom: 1px solid #00a651;
  width: 100%;
  max-width: 65px;
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-outer-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]:invalid,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type="number"]:out-of-range {
  border: 1px solid #ff6347;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper span {
  display: block;
  min-width: 12px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper p {
  font-size: 13px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-clear {
  color: #444;
  border: none;
  font-size: 12px;
  font-weight: 600;
  background: none;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-filter {
  padding: 0;
  border: none;
  background: #00a651;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-filter:hover {
  background: #007338;
}
section.kategoria-section-content .content-flexbox .flexbox-body {
  width: 75%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
  font-size: 13px;
  color: #777;
  position: relative;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
  margin-right: 30px;
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button {
  margin-right: 7px;
  padding: 0;
  border: none;
  background: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a img,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button img {
  width: 15px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a:last-child,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button:last-child {
  margin-right: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button.active {
  background: #00a651;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading {
  margin-top: 60px;
  margin-bottom: 15px;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading p {
  padding-bottom: 10px;
  font-size: 22px;
  color: #444;
  font-weight: 600;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta {
  margin-top: 40px;
  display: block;
  position: relative;
  transition: border-radius 0.4s;
  overflow: hidden;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta img {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  padding: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner p {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner p strong {
  font-size: 31px;
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta:hover {
  border-radius: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-more {
  margin-top: 65px;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-more .btn {
  width: 400px;
  max-width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
  width: 38%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery img {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSPrev {
  width: 25px;
  height: 25px;
  background: url("../library/lightslider-master/dist/img/prev.svg") center;
  background-size: cover;
  margin-top: 0px;
  transform: translateY(-50%);
  left: 1px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSNext {
  width: 25px;
  height: 25px;
  background: url("../library/lightslider-master/dist/img/prev.svg") center;
  background-size: cover;
  margin-top: 0px;
  transform: translateY(-50%) rotate(180deg);
  right: 1px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery {
  margin-top: 23px !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li {
  border: none;
  opacity: 0.6;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li:hover {
  opacity: 1;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
  width: 55%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .code {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .code strong {
  font-weight: 700;
  color: #00a651;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description p {
  font-size: 14px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .symbol {
  margin-top: 20px;
  padding: 10px;
  background: #f9f9f9;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .symbol p {
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .symbol p strong {
  font-weight: 700;
  color: #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .symbol ul {
  margin-top: 10px;
  margin-left: 66px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .symbol ul li {
  color: #999;
  font-weight: 700;
  font-size: 12.5px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .symbol ul li.list-heading {
  font-size: 14px;
  font-weight: 400;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features > *:not(:first-child) {
  margin-top: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p {
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p strong {
  font-weight: 700;
  color: #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li a {
  display: block;
  background: rgba(247, 247, 247, 0.9372549);
  padding: 8px 10px;
  font-size: 13px;
  border-left: 2px solid transparent;
  color: #333;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li a:hover {
  border-color: #fff200;
  color: #00a651;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li.heading {
  margin-top: 0;
  text-align: left;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li:nth-child(even) a {
  background: #efefef;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price {
  margin-top: 40px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-netto {
  color: #00a651;
  font-size: 40px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-netto span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto {
  margin-top: 10px;
  font-weight: 400;
  color: #999;
  font-size: 18px;
  line-height: 18px;
  padding-left: 3px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number {
  width: 70px;
  padding: 0 12px;
  text-align: center;
  outline: none;
  background: none;
  border: none;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  height: 35px;
  user-select: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  min-width: 35px;
  background: none;
  color: #999;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: 1px solid #999;
  outline: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement:active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background: #00a651;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-inner-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type="number"] {
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .related .product-service {
  width: 18%;
}
section.aktualnosci-section-content {
  padding-bottom: 50px;
}
section.aktualnosci-section-content .section-heading {
  text-align: center;
  margin-bottom: 50px;
}
section.aktualnosci-section-content .section-heading h2 {
  font-size: 22px;
  color: #444;
  font-weight: 600;
}
section.aktualnosci-section-content .section-heading .heading-categories {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
section.aktualnosci-section-content .section-heading .heading-categories .btn {
  margin: 5px;
}
section.aktualnosci-section-content .news-flexbox {
  margin-top: calc(-8% / 3);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service {
  margin-top: calc(8% / 3);
  width: 23%;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service .service-image {
  padding-top: 63%;
  width: 100%;
  position: relative;
  border-radius: 2px;
  transition: all 0.4s;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service .service-name {
  margin-top: 15px;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service .service-name .date {
  color: #00a651;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service .service-name .date strong {
  margin-right: 10px;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service .service-name p {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  transition: all 0.4s;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service:hover .service-image {
  opacity: 0.8;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service:hover .service-name p {
  color: #00a651;
}
section.aktualnosci-section-content .news-flexbox .flexbox-service.service-hidden {
  opacity: 0;
  visibility: hidden;
  margin: 0;
  padding: 0;
}
section.static-page-section-content {
  padding: 0 0 70px 0;
}
section.static-page-section-content .content-heading {
  text-align: center;
}
section.static-page-section-content .content-heading h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
section.static-page-section-content .content-heading .date {
  margin-top: 15px;
  color: #00a651;
}
section.static-page-section-content .content-heading .date strong {
  margin-right: 10px;
}
section.static-page-section-content .content-inner {
  width: 70%;
  margin: 40px auto 0;
  text-align: center;
}
section.static-page-section-content .content-inner > *:not(:first-child) {
  margin-top: 20px;
}
section.static-page-section-content .content-inner p,
section.static-page-section-content .content-inner li {
  font-size: 15px;
  line-height: 26px;
  color: #444;
}
section.static-page-section-content .content-inner ul,
section.static-page-section-content .content-inner ol {
  padding-left: 17px;
}
section.static-page-section-content .content-offer-links {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.static-page-section-content .content-offer-links .offer-link {
  position: relative;
  width: 24%;
  padding-top: 22%;
  margin: 7px 0;
  background: #f1f1f1;
  filter: grayscale(50%);
}
section.static-page-section-content .content-offer-links .offer-link .offer-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00a651;
  opacity: 0.35;
  transition: all 0.4s;
}
section.static-page-section-content .content-offer-links .offer-link .offer-name {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.static-page-section-content .content-offer-links .offer-link .offer-name .btn-default {
  font-size: 12px;
  padding: 5px;
  width: 100%;
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.static-page-section-content .content-offer-links .offer-link:hover .offer-background {
  opacity: 0.6;
}
section.static-page-section-content .content-offer-links .box-hidden {
  width: 24%;
  visibility: hidden;
  opacity: 0;
}
section.static-page-section-content .media-flexbox {
  padding-top: 50px;
  margin-top: calc(-5% / 4);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper {
  margin-top: calc(5% / 4);
  border-radius: 2px;
  width: 19%;
  position: relative;
  overflow: hidden;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper .flexbox-service {
  width: 100%;
  padding-top: 66%;
  display: block;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper .flexbox-service .service-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper .flexbox-service .service-inner img {
  width: 100%;
  transition: all 0.3s;
}
section.static-page-section-content .media-flexbox .flexbox-service-wrapper.service-hidden {
  margin-top: 0;
}
.content-contact {
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}
.content-contact .contact-service-category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 30px 0 -30px 0;
  padding: 0;
}
.content-contact .contact-service-category h2 {
  font-size: 22px;
}
.content-contact .contact-service .service-image {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin: 0 auto;
}
.content-contact .contact-service .name {
  margin-top: 10px;
  margin-bottom: 10px;
}
.content-contact .contact-service .job {
  margin-top: -10px;
  font-size: 12px;
}
.content-contact .contact-service .phone {
  margin-top: 3px;
  display: block;
  font-size: 10px;
  color: #fff;
  background: #00a651;
  padding: 0 5px;
  border-radius: 4px;
}
.content-contact .contact-service:not(:first-child) {
  margin-left: 50px;
}
section.section-popular {
  padding-bottom: 50px;
}
section.kontakt-section-content {
  padding: 0 0 70px 0;
  position: relative;
}
section.kontakt-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-content .content-flexbox .flexbox-heading {
  white-space: nowrap;
  padding-right: 70px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading h2 {
  color: #444;
  font-size: 22px;
  font-weight: 400;
}
section.kontakt-section-content .content-flexbox .flexbox-heading h3 {
  margin-top: 8px;
  color: #00a651;
  font-size: 45px;
  font-weight: 800;
}
section.kontakt-section-content .content-flexbox .flexbox-heading address {
  margin-top: 20px;
  color: #666;
  font-size: 18px;
  font-weight: 400;
}
section.kontakt-section-content .content-flexbox .flexbox-heading address small {
  margin-top: 20px;
  display: block;
  color: #999;
  margin-bottom: 7px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading address a {
  font-weight: 700;
  color: #00a651;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form {
  margin-top: 25px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service {
  margin-top: 15px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service label {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  width: 100%;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service input,
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service textarea {
  width: 100%;
  display: block;
  border: none;
  border-radius: 5px;
  height: 45px;
  padding: 10px;
  background: #f2f2f2;
  transition: box-shadow 0.4s;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service input:focus,
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service textarea:focus {
  outline: none;
  box-shadow: 0px 0px 2px 0px #2ca9cd;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service input.warning,
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service textarea.warning {
  box-shadow: 0px 0px 2px 0px #b31616;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .input-service textarea {
  height: 150px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading form .btn {
  margin-top: 20px;
}
section.kontakt-section-content .content-flexbox .content-contact {
  width: 100%;
  margin-top: -20px;
  justify-content: space-between;
}
section.kontakt-section-content .content-flexbox .content-contact .contact-service {
  margin-top: 30px;
  width: 24%;
  margin-left: 0;
}
section.kontakt-section-content .content-flexbox .content-contact .contact-service .name {
  font-size: 15px;
}
section.kontakt-section-content .content-iframe {
  margin-top: 60px;
  width: 100%;
  height: 400px;
  position: relative;
}
section.kontakt-section-content .content-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
}
section.section-footer {
  border-top: 1px solid #e1e1e1;
  background: #f1f1f1;
  position: relative;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 35px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-logo img {
  height: 47px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description {
  margin-top: 20px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description p {
  color: #444;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 5px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 18px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 13px;
  color: #444;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #00a651;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .alert {
  position: relative;
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 35px;
  padding: 0;
  background: #f1f1f1;
}
section.section-footer .alert p {
  font-size: 14px;
  color: #444;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #00a651;
  cursor: pointer;
}
section.section-footer .footer-credits-wrapper {
  margin-top: 35px;
  padding: 15px 0;
  border-top: 1px solid #e1e1e1;
  background: #e9e9e9;
}
section.section-footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits-wrapper .footer-credits p {
  font-size: 14px;
  color: #444;
  font-weight: 300;
}
section.section-footer .footer-credits-wrapper .footer-credits p a {
  color: #00a651;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment p {
  margin-right: 15px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img {
  margin-right: 10px;
  height: 19px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img:last-child {
  margin-right: 0;
}
@media (min-width: 1199.98px) {
  .container {
    max-width: 1260px;
  }
}
@media (max-width: 1199.98px) {
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine input {
    width: 200px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .engine-select-wrapper {
    min-width: 100px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .engine-select-wrapper select {
    padding: 14px 25px 14px 5px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    padding: 10px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description h2 {
    font-size: 20px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description .btn {
    padding: 8px 15px;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-top-wrapper .header-top {
    flex-direction: column;
  }
  nav.section-header .header-top-wrapper .header-top ul:first-of-type {
    margin-bottom: 10px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox {
    flex-direction: column;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine {
    margin-bottom: 25px;
  }
  nav.section-header .header-management {
    flex-wrap: wrap;
    justify-content: center;
  }
  nav.section-header .header-management .management-logo {
    order: 1;
    margin-bottom: 20px;
  }
  nav.section-header .header-management .management-search-engine {
    margin-top: 25px;
    order: 3;
    width: 100%;
  }
  nav.section-header .header-management .management-service {
    order: 2;
  }
  .select-wrapper select {
    padding: 5px 50px 5px 10px;
  }
  .products-flexbox.wyszukiwanie .product-service {
    width: 31%;
  }
  .products-flexbox .product-service .btn-more {
    margin-top: 0;
  }
  section.section-welcome .welcome-image-1 {
    left: -130px;
  }
  section.section-welcome .welcome-image-2 {
    right: -130px;
  }
  section.section-latest .section-heading-wrapper .section-heading:nth-child(1) {
    width: 100%;
    text-align: left;
  }
  section.section-latest .section-heading-wrapper .section-heading:nth-of-type(2) {
    display: none;
  }
  section.section-latest .products-flexbox .product-service {
    width: 31%;
    margin-top: 40px;
  }
  section.section-latest .products-flexbox .service-hidden {
    width: 31%;
  }
  section.section-latest .products-flexbox .product-cta {
    display: none;
  }
  section.section-latest .btn-mobile {
    display: inline-block;
    margin-top: 30px;
  }
  section.section-cta .cta-flexbox {
    grid-gap: 12px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description {
    width: 65%;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description h2 {
    font-size: 16px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description h2::after {
    margin-top: 3px;
    height: 4px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description .btn {
    padding: 8px 6px;
    font-size: 12px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-image {
    width: 35%;
  }
  section.section-advantages .advantages-flexbox {
    flex-wrap: wrap;
  }
  section.section-advantages .advantages-flexbox .advantage-service {
    flex-direction: column;
  }
  section.section-advantages .advantages-flexbox .advantage-service p {
    font-size: 13px;
    padding: 0;
    margin-top: 5px;
  }
  section.static-page-section-content .content-offer-links .offer-link {
    width: 49%;
    padding-top: 40%;
  }
  section.static-page-section-content .content-offer-links .offer-link .btn-default {
    min-height: 50px;
  }
  section.static-page-section-content .content-offer-links .box-hidden {
    width: 49%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related .product-service {
    width: 32%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox.grid-list .product-service {
    width: 31%;
    padding: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox.grid-list .product-service .name {
    font-size: 12.5px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox.grid-list .product-service .symbol {
    font-size: 10.5px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox.grid-list .product-service .footer {
    margin-top: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service {
    width: 31%;
  }
  section.kontakt-section-content .content-flexbox .content-contact {
    display: flex;
    flex-wrap: wrap;
  }
  section.kontakt-section-content .content-flexbox .content-contact .contact-service {
    width: 47%;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) {
    margin-left: 15px;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) ul li.heading {
    font-size: 14px;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) .list-social {
    justify-content: flex-start;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-top-wrapper .header-top {
    justify-content: center;
  }
  nav.section-header .header-top-wrapper .header-top ul li:not(:last-child) {
    margin-right: 15px;
  }
  nav.section-header .header-top-wrapper .header-top p {
    display: none;
  }
  nav.section-header .header-management .management-logo .logo-slogan {
    margin-left: 30px;
    font-size: 16px;
  }
  .products-flexbox.grid-list .product-service {
    align-items: center;
  }
  .products-flexbox.grid-list .product-service .badge-wrapper .badge {
    width: 90px;
    height: 30px;
    font-size: 14px;
    transform: translateY(-105%);
  }
  .products-flexbox.grid-list .product-service header {
    min-width: 40%;
  }
  .products-flexbox.grid-list .product-service .footer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
  .products-flexbox.grid-list .product-service .price,
  .products-flexbox.grid-list .product-service .price-small {
    position: static;
  }
  .products-flexbox.grid-list .product-service .btn-more {
    margin-top: 15px;
  }
  section.section-welcome .welcome-image-1 {
    left: -250px;
  }
  section.section-welcome .welcome-image-2 {
    right: -220px;
  }
  section.section-cta .cta-flexbox {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description {
    width: 60%;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description h2 {
    font-size: 22px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-description .btn {
    padding: 8px 15px;
    font-size: 16px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-image {
    width: 40%;
  }
  section.section-latest .products-flexbox .product-service {
    width: 47%;
    margin-top: 30px;
  }
  section.section-latest .products-flexbox .product-service:nth-of-type(3) {
    display: none;
  }
  section.section-advantages {
    padding-top: 50px;
  }
  section.section-advantages .advantages-flexbox {
    flex-wrap: wrap;
  }
  section.section-advantages .advantages-flexbox .advantage-service {
    width: 48%;
    margin-top: 20px;
  }
  section.section-advantages .advantages-flexbox .advantage-service p {
    font-size: 13px;
    padding: 0;
    margin-top: 5px;
  }
  section.kategoria-section-content .content-flexbox {
    flex-direction: column;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
    order: 2;
    width: 60%;
    padding-top: 30px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
    width: 100%;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .download-list li.heading {
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info table {
    width: 100% !important;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info table tr {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info table tr td {
    height: auto !important;
    width: 100% !important;
    display: inline-block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info table tr td:first-of-type {
    font-weight: 600;
    margin-bottom: 5px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related .product-service:nth-of-type(2) {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service {
    width: 47%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    width: 100%;
    padding: 50px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
    font-size: 18px;
    padding: 8px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
    padding-left: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav {
    min-width: 40%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-filter {
    padding: 7px 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
    display: flex;
    justify-content: center;
    padding: 15px 0;
    margin-bottom: 40px;
  }
  section.aktualnosci-section-content {
    padding-bottom: 50px;
  }
  section.aktualnosci-section-content .news-flexbox .flexbox-service {
    width: 31%;
  }
  section.aktualnosci-section-content .news-flexbox .flexbox-service .service-name p {
    font-size: 13px;
  }
  section.static-page-section-content .content-contact .contact-service {
    width: 26%;
  }
  section.static-page-section-content .media-flexbox .flexbox-service-wrapper {
    width: 32%;
  }
  section.kontakt-section-content .content-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .content-flexbox .flexbox-heading {
    padding-right: 0;
    text-align: center;
  }
  section.kontakt-section-content .content-flexbox .content-contact {
    padding-top: 30px;
  }
  section.kontakt-section-content .content-flexbox .content-contact .contact-service {
    width: 30%;
  }
  section.kontakt-section-content .content-flexbox .content-contact .contact-service .name {
    font-size: 14px;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) {
    margin-left: 0;
    margin-top: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) ul li.heading {
    font-size: 20px;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) ul li a {
    font-size: 16px;
  }
  section.section-footer .footer-flexbox .flexbox-service:not(:first-of-type) .list-social {
    justify-content: flex-end;
  }
  section.section-footer .footer-flexbox .service-about .service-description p {
    text-align: center;
  }
  section.section-footer .alert p {
    text-align: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits,
  section.section-footer .footer-credits-wrapper .footer-payment {
    flex-direction: column;
  }
  section.section-footer .footer-credits-wrapper .footer-credits p,
  section.section-footer .footer-credits-wrapper .footer-payment p {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  nav.section-header .header-top-wrapper .header-top ul:first-of-type {
    display: none;
  }
  nav.section-header .header-top-wrapper .header-top ul li:not(:last-child) {
    margin-right: 10px;
  }
  nav.section-header .header-top-wrapper .header-top ul li:not(:last-child):after {
    margin-left: 5px;
  }
  nav.section-header .header-top-wrapper .header-top .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-management {
    padding: 15px 0;
  }
  nav.section-header .header-management .management-logo .logo-slogan {
    display: none;
  }
  nav.section-header .header-management .management-logo img {
    margin-right: 15px;
  }
  nav.section-header .header-management .management-service {
    width: 100%;
    justify-content: space-around;
  }
  nav.section-header .header-management .management-service li:not(:last-child) {
    margin-right: 20px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine {
    margin-bottom: 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine input {
    width: 155px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    padding: 20px;
    font-size: 16px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .breadcrumb-wrapper {
    display: none;
  }
  .select-wrapper select {
    width: 150px;
    padding: 5px 30px 5px 10px;
  }
  .products-flexbox.wyszukiwanie .product-service {
    width: 47%;
  }
  section.section-welcome .welcome-image-1 {
    left: -245px;
  }
  section.section-welcome .welcome-image-2 {
    right: -230px;
  }
  section.section-welcome .welcome-inner h1 {
    font-size: 35px;
    line-height: 50px;
  }
  section.kategoria-section-content {
    padding: 50px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
    margin-right: 15px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
    font-size: 10px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
    width: 100%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info h2 {
    font-size: 32px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related .badge-wrapper .badge {
    width: 90px;
    height: 30px;
    font-size: 14px;
    transform: translateY(-105%);
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related .product-service {
    width: 80%;
    margin-top: 40px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related .product-service:nth-of-type(5) {
    display: none;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .related .product-service .btn-more {
    font-size: 14px;
  }
  section.aktualnosci-section-content {
    padding: 50px 0;
  }
  section.aktualnosci-section-content .news-flexbox .flexbox-service {
    width: 47%;
  }
  section.static-page-section-content {
    padding: 50px 0;
  }
  section.static-page-section-content .content-heading h2 {
    font-size: 32px;
  }
  section.static-page-section-content .content-inner {
    width: 100%;
  }
  section.static-page-section-content .media-flexbox {
    padding-top: 20px;
  }
  section.static-page-section-content .media-flexbox .flexbox-service-wrapper {
    width: 48%;
    margin-top: 15px;
  }
  section.static-page-section-content .media-flexbox .flexbox-service-wrapper:nth-of-type(5) {
    display: none;
  }
  section.static-page-section-content .content-contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  section.static-page-section-content .content-contact .contact-service {
    width: 40%;
  }
  section.static-page-section-content .content-contact .contact-service:not(:first-child) {
    margin-left: 0;
    margin-top: 30px;
  }
  section.section-popular {
    padding: 30px 0 50px 0;
  }
  section.kategoria-section-heading h1 {
    font-size: 28px;
  }
  section.kontakt-section-content {
    padding: 50px 0;
  }
}
@media (max-width: 500px) {
  .products-flexbox.grid-list .product-service header {
    min-width: 50%;
  }
  .products-flexbox.grid-list .product-service .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .products-flexbox.grid-list .product-service .btn-more {
    margin-top: 7px;
  }
  .products-flexbox.wyszukiwanie {
    justify-content: center;
  }
  .products-flexbox.wyszukiwanie .product-service {
    margin-top: 50px;
    width: 80%;
  }
  .products-flexbox.wyszukiwanie .product-service .badge-wrapper .badge {
    width: 90px;
    height: 30px;
    font-size: 14px;
    transform: translateY(-105%);
  }
  .products-flexbox.wyszukiwanie .product-service .btn-more {
    font-size: 14px;
  }
  section.section-welcome .welcome-image-1 {
    left: -265px;
    opacity: 0.5;
  }
  section.section-welcome .welcome-image-2 {
    right: -250px;
    opacity: 0.5;
  }
  section.section-welcome .welcome-inner h1 {
    font-size: 28px;
    line-height: 40px;
  }
  section.section-latest .products-flexbox {
    align-items: center;
    justify-content: center;
  }
  section.section-latest .products-flexbox .product-service {
    width: 80%;
    margin-top: 40px;
  }
  section.section-latest .products-flexbox .product-service .badge-wrapper .badge {
    width: 90px;
    height: 30px;
    font-size: 14px;
    transform: translateY(-105%);
  }
  section.section-latest .products-flexbox .product-service .btn-more {
    font-size: 14px;
  }
  section.section-latest .btn-mobile {
    margin-top: 0;
    transform: translate(12%, 100%);
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox {
    justify-content: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox.grid-list .product-service {
    padding: 10px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox.grid-list .product-service .btn-more {
    font-size: 10px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service {
    width: 80%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .products-flexbox .product-service .btn-more {
    font-size: 14px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav {
    min-width: 70%;
  }
  section.kontakt-section-content .content-flexbox .content-contact {
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .content-flexbox .content-contact .contact-service {
    width: 45%;
  }
  section.kontakt-section-content .content-flexbox .content-contact .contact-service .name {
    font-size: 16px;
  }
}
